#date-buttons
  padding: 0
  background-color: $grey
  position: relative
  .is-touch &
    display: block
  .nav-button
    border-radius: 0
    width: 20%
    display: inline-block
    vertical-align: top
    background-color: $grey
    transition: background-color 0.2s ease-in-out
    &.image.is-1by1
      padding-top: 19%
    .nav-button-content
      padding: 1rem
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      p
        color: white
        font-weight: 500
        font-size: 1rem
        text-transform: uppercase
        +tablet
          font-size: 1.4rem
        +desktop
          font-size: 1.7rem
  .clickable-link
    user-select: none
    cursor: pointer
  .clickable-link.is-disabled > .nav-button
    cursor: not-allowed
    background-color: #000
    .nav-button-content
      p
        color: #676767
  .clickable-link:not(.is-disabled)
    &.is-today > .nav-button
      background-color: #3C766E
      .nav-button-content
        p
          color: white
    &:hover > .nav-button,
    &:focus > .nav-button,
    &.active > .nav-button
      background-color: $planGreen
      .nav-button-content
        p
          color: white
  .clickable-link:not(:last-child) .nav-button
    border-right: 1px solid rgba(255, 255, 255, 0.3)