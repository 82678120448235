$input-color: $text-strong !default
$input-background-color: $scheme-main !default
$input-border-color: $border !default
$input-height: $control-height !default
$input-shadow: inset 0 0.0625em 0.125em rgba($scheme-invert, 0.05) !default
$input-placeholder-color: rgba($input-color, 0.3) !default

$input-hover-color: $text-strong !default
$input-hover-border-color: $border-hover !default

$input-focus-color: $text-strong !default
$input-focus-border-color: $link !default
$input-focus-box-shadow-size: 0 0 0 0.125em !default
$input-focus-box-shadow-color: rgba($link, 0.25) !default

$input-disabled-color: $text-light !default
$input-disabled-background-color: $background !default
$input-disabled-border-color: $background !default
$input-disabled-placeholder-color: rgba($input-disabled-color, 0.3) !default

$input-arrow: $link !default

$input-icon-color: $border !default
$input-icon-active-color: $text !default

$input-radius: $radius !default

=input
  @extend %control
  background-color: $input-background-color
  border-color: $input-border-color
  border-radius: $input-radius
  color: $input-color
  +placeholder
    color: $input-placeholder-color
  &:hover,
  &.is-hovered
    border-color: $input-hover-border-color
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    border-color: $input-focus-border-color
    box-shadow: $input-focus-box-shadow-size $input-focus-box-shadow-color
  &[disabled],
  fieldset[disabled] &
    background-color: $input-disabled-background-color
    border-color: $input-disabled-border-color
    box-shadow: none
    color: $input-disabled-color
    +placeholder
      color: $input-disabled-placeholder-color

%input
  +input
