.loading-overlay
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  height: 100%
  width: 100%
  z-index: 2000
  background-color: rgba(255, 255, 255, 0.7)
  backdrop-filter: blur(6px)
  .spinner
    position: absolute
    width: 128px
    height: 128px
    top: calc(50% - 64px)
    left: calc(50% - 64px)
    transform: perspective(206px) rotateX(45deg)
    .outer
      box-sizing: border-box
      animation: spin 3s linear infinite
      height: 100%
      .inner
        position: absolute
        border-radius: 50%
        width: 64px
        height: 64px
        animation: spin 1.8s ease-in-out infinite
        &.tl
          top: 0
          left: 0
          border-top: 2px solid $goRed
          border-left: 4px solid $goRed
        &.tr
          top: 0
          right: 0
          border-top: 2px solid $reflectBlue
          border-right: 4px solid $reflectBlue
        &.br
          bottom: 0
          right: 0
          border-bottom: 2px solid $goRed
          border-right: 4px solid $goRed
        &.bl
          bottom: 0
          left: 0
          border-bottom: 2px solid $reflectBlue
          border-left: 4px solid $reflectBlue

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)