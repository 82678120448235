*
  box-sizing: border-box

html, body
  height: 100%
  width: 100%
  margin: 0
  padding: 0

#root
  min-height: 100%
  width: 100%
  background: $body-background
  background: $body-background-gradient
  overflow-x: auto
  overflow-y: hidden
  display: flex

#app
  flex: 1 0 auto
  background-color: #fff
  width: 100%
  display: flex
  flex-direction: column
  position: relative
  overflow: hidden
  user-select: none
  +desktop
    // border-left: 1px solid #e0e0e0
    // border-right: 1px solid #e0e0e0
    box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.3)
    max-width: 800px
  +widescreen
    max-width: 900px
  +fullhd
    max-width: 900px

#daily-image
  overflow: hidden
  padding: 0
  video, img
    pointer-events: none

#bottom-bars
  overflow: hidden
  padding: 0
  position: relative
  line-height: 0
  &::after
    box-shadow: inset 0px 12px 40px -10px rgba(0,0,0,0.5)
    content: ''
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
  .is-touch &
    // overflow: visible
    overflow: hidden
  .bottom-bar
    width: 20%
    height: 40px
    display: inline-block
    vertical-align: top
    position: relative
    &.pause-bar
      background-color: $pauseBlue
      & > p
        color: $pauseBlue
    &.read-bar
      background-color: $readBlue
      & > p
        color: $readBlue
    &.reflect-bar
      background-color: $reflectBlue
      & > p
        color: $reflectBlue
    &.plan-bar
      background-color: $planGreen
      & > p
        color: $planGreen
    &.go-bar
      background-color: $goRed
      & > p
        color: $goRed
    & > p
      display: block
      position: absolute
      bottom: 55px
      width: 100%
      text-align: center
      font-weight: 700