.date-display-box
  position: absolute
  top: 0
  left: 50%
  transform: translate(-50%, -100%)
  padding: 0.5rem 1.5rem
  background-color: #dc5258
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  width: 90%
  max-width: max-content
  & > p
    color: #494242
    font-size: 1.5rem
    text-transform: uppercase
    font-weight: 600
    text-align: center
    line-break: strict