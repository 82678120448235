$tile-spacing: 0.75rem !default

.tile
  align-items: stretch
  display: block
  flex-basis: 0
  flex-grow: 1
  flex-shrink: 1
  min-height: min-content
  // Modifiers
  &.is-ancestor
    margin-left: $tile-spacing * -1
    margin-right: $tile-spacing * -1
    margin-top: $tile-spacing * -1
    &:last-child
      margin-bottom: $tile-spacing * -1
    &:not(:last-child)
      margin-bottom: $tile-spacing
  &.is-child
    margin: 0 !important
  &.is-parent
    padding: $tile-spacing
  &.is-vertical
    flex-direction: column
    & > .tile.is-child:not(:last-child)
      margin-bottom: 1.5rem !important
  // Responsiveness
  +tablet
    &:not(.is-child)
      display: flex
    @for $i from 1 through 12
      &.is-#{$i}
        flex: none
        width: ($i / 12) * 100%
