#nav-buttons
  padding: 0
  background-color: $grey
  position: relative
  .is-touch &
    display: block
  .nav-button
    width: 20%
    display: inline-block
    vertical-align: top
    transition: background-color 0.2s ease-in-out
    &.image.is-1by1
      padding-top: 20%
    .nav-button-content
      padding: 1rem
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      svg
        height: 100%
        width: auto
        max-height: 60px
      p
        color: white
        font-weight: 500
        font-size: 1rem
        text-transform: uppercase
        +tablet
          font-size: 1.4rem
        +desktop
          font-size: 1.7rem
  .clickable-link
    user-select: none
    cursor: pointer
  .clickable-link:hover > .nav-button,
  .clickable-link:focus > .nav-button,
  .clickable-link.active > .nav-button
    &.pause-button
      background-color: $pauseBlue
    &.read-button
      background-color: $readBlue
    &.reflect-button
      background-color: $reflectBlue
    &.plan-button
      background-color: $planGreen
    &.go-button
      background-color: $goRed
  .clickable-link:not(:last-child) .nav-button
      border-right: 1px solid rgba(255, 255, 255, 0.3)