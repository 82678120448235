.animation-wrapper
  flex: 1 0 auto
#main-content
  min-height: 100%
  display: flex
  flex-direction: column
  .content
    font-size: 1.3rem
    strong
      color: inherit
    &.primary
      margin-bottom: 0
      flex: 1 1 auto
    &.reference
      font-size: 0.8rem
    .welcome-title
      font-size: 2.4rem
      font-weight: normal
      margin-bottom: 0.5rem
    .welcome-image
      margin: 0 auto 0.5rem
      max-width: 300px
      pointer-events: none
      figure.image
        margin: 0
  hr.reference-hr
    margin-top: 1rem
    margin-bottom: 1rem
    background-color: rgba(245, 245, 245, 1)
  .touch-page-header
    display: none
  .swipe-indicator
    display: none
    width: 100%
    flex-direction: column
    justify-content: center
    align-items: center
    svg
      color: $reflectBlue
      animation: swipe 3s ease-in-out 2s 12 normal both
  // .is-touch &
  //   &.home .content.primary
  //     margin-bottom: 1.5rem
  //     flex: 0 1 auto
  //   hr.reference-hr
  //     background-color: rgba(245, 245, 245, 0.25)
  //   .touch-page-header
  //     display: flex
  //     align-items: center
  //     margin-bottom: 3rem
  //     .icon-wrapper
  //       width: 50px
  //       svg
  //         width: 100%
  //     .page-title-wrapper
  //       font-size: 1.8rem
  //       text-transform: uppercase
  //       padding: 0.25rem 1rem
  //   .swipe-indicator
  //     display: flex
    // &.pause
    //   background-color: $pauseBlue
    //   color: #fff !important
    //   & > .content > *
    //     color: #fff !important
    // &.read
    //   background-color: $readBlue
    //   color: #fff !important
    //   & > .content > *
    //     color: #fff !important
    // &.reflect
    //   background-color: $reflectBlue
    //   color: #fff !important
    //   & > .content > *
    //     color: #fff !important
    // &.plan
    //   background-color: $planGreen
    //   color: #fff !important
    //   & > .content > *
    //     color: #fff !important
    // &.go
    //   background-color: $goRed
    //   color: #fff !important
    //   & > .content > *
    //     color: #fff !important

@keyframes swipe
  0%
    transform: translateX(0px) scale(1)
  20%
    transform: translateX(20px) scale(0.8)
  40%
    transform: translateX(-20px) scale(0.8)
  60%
    transform: translateX(0px) scale(1)
  100%
    transform: translateX(0px) scale(1)