.top-logo
  display: flex
  padding: 0.5rem 2.25rem 0.5rem
  position: relative
  justify-content: center
  align-items: center
  user-select: none
  &.has-exit-link
    justify-content: space-between
  .clickable-link
    cursor: pointer
    display: flex
    user-select: none
  img
    max-width: 96px
    margin-left: 0
    margin-right: 0
    opacity: 0.4
    +tablet
      max-width: 110px

.button.exit-btn
  background-color: $goRed
  display: flex
  height: auto
  line-height: 1.1rem
  padding-left: 0.75rem
  padding-right: 0.75rem
  font-size: 0.9rem
  max-width: 150px
  transition: background-color 0.2s ease-in-out
  svg
    margin: -0.25rem 0.25rem -0.25rem -0.25rem
    // margin-right: 0.5rem
    flex: 1 0 auto
  span
    display: block
    text-align: center
    white-space: normal
    word-break: break-word
    flex: 1 1 auto
  &:hover, &:focus, &:active
    background-color: lighten($goRed, 10%)